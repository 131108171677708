import React from 'react'
import Select from 'react-select'
import './styles.sass'

const DropDown = () => {
  const handleChange = (e) => {
    console.log('handleChange', e)
  }

  const options = [
    { value: 'NEW', label: 'По добавлению' },
    { value: 'YEAR', label: 'По году' },
    { value: 'ALPH', label: 'По режиссерам' },
  ]

  const customStyles = {
    container: (provided, _) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0',
      border: 'none',
      boxShadow: 'none',
      outline: (state.isFocused ? '2px solid #000' : 'none'),
      cursor: 'pointer'
    }),
    singleValue: () => ({
      color: '#000',
      fontWeight: 700,
      fontSize: '1rem',
      textTransform: 'lowercase',
    }),
    dropdownIndicator: (provided, _) => ({
      ...provided,
      color: '#000',
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (provided, _) => ({
      ...provided,
      borderRadius: '0',
      border: 'none',
    }),
    menuList: (provided, _) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => {
      let backgroundColor = '#fff', color = '#000'

      if (state.isFocused) {
        backgroundColor = '#eee'
      }

      if (state.isSelected) {
        backgroundColor = '#000'
        color = '#fff'
      }

      return {
        ...provided,
        color,
        fontSize: '1rem',
        backgroundColor,
        fontWeight: 700,
        textTransform: 'lowercase',
        cursor: (state.isFocused ? 'pointer' : 'auto'),
        ':active': {
          backgroundColor: '#ddd',
        }
      }
    }
  }

  return (
    <div className="drop-down">
      <Select
        isSearchable={false}
        options={options}
        defaultValue={options[0]}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  )
}

export default DropDown