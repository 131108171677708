import React from 'react'
import './styles.sass'

const NotFound = () => {
  return (
    <div className="not-found">
      <h1><span>404 Страница в работе</span></h1>
    </div>
  )
}

export default NotFound