import React, { useState, useEffect } from 'react'
import Loading from '../../components/loading'
import movies from '../../movies.json'
import './styles.sass'
import LazyLoad from 'react-lazyload'

import DropDown from '../../components/drop-down/DropDown'

const Movies = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [sorting, setSorting] = useState('NEW')

  useEffect(() => {
    setTimeout(setIsLoading, 500, false)
  }, [])

  const sort = (a, b) => {
    if (sorting === 'YEAR') return new Date(a.year) - new Date(b.year);

    return new Date(a.id) - new Date(b.id);
  }

  const moviesList = movies.sort(sort).map(item => {
    return (
      <div key={item.id} className="movie" tabIndex="0" type="button">
        <LazyLoad className="movie__img-container">
          <img className="movie__img" src={item.poster} alt={item.title} />
        </LazyLoad>
        <div className="movie__info">
          <div className="movie__ru">{item.ru}</div>
          <div className="movie__title">
            <span>{item.title}</span>
            <span>{item.title}</span>
            <span>{item.title}</span>
          </div>
          <div className="movie__director">{item.director}</div>
          <div className="movie__year">{item.year}</div>
        </div>
      </div>
    )
  })

  if (isLoading) return (
    <div className="container">
      <Loading />
    </div>
  )

  return (
    <div className="container">
      <div className="container__title">
        <h1><span>К</span><span>И</span><span>Н</span><span>О</span></h1>
        <DropDown  />
      </div>

      <div className="movies">
        {moviesList}
      </div>
    </div>
  )
}

export default Movies