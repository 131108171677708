import React from 'react'
import { Link } from 'react-router-dom'
import './styles.sass'

const Header = () => {
  return (
    <div className="header">
      <Link to="/" className="logo">
        <span>Eenuuii</span>
        <span>Eenuuii</span>
        <span>Eenuuii</span>
      </Link>

      <ul>
        <li><Link to="/">Кино</Link></li>
        <li><Link to="/bands">Музыка</Link></li>
        <li><Link to="/about">О сайте</Link></li>
      </ul>

      <div className="socials">
        <a href="/" target='_blank' rel='nofollow'><i className="fab fa-instagram"></i></a>
      </div>
    </div>
  )
}

export default Header