import React from 'react'
import Header from './components/header/Header'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Movies from './pages/movies/Movies'
import NotFound from './pages/404'

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path='/' component={Movies} />
        <Route path='*' component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
